import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAnoObWD2KrMPSyuJQX8pdTCe61HgXZGWI",
  authDomain: "zonar-app-prod.firebaseapp.com",
  databaseURL: "https://zonar-app-prod.firebaseio.com",
  projectId: "zonar-app-prod",
  storageBucket: "zonar-app-prod.appspot.com",
  messagingSenderId: "342170682786",
  appId: "1:342170682786:web:8fee08eafe2b9417c15efb",
  measurementId: "G-VDPVYKJB9D",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();

export { firebase, db, auth };
