import React, { useEffect } from "react";
import { useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Services from "../components/Services";
import currentAdress from "../utils/helpers/currentAdress";

const Home = () => {
  useEffect(() => {
    const isLocation = JSON.parse(localStorage.getItem("userLatLng"));
    currentAdress(isLocation?.lat, isLocation?.lng);
   
  }, []);

  return (
    <>
      {/* Header */}
      <Header />

      {/* Service Section */}
      <Services />

      {/* footer  */}
      <Footer />
    </>
  );
};

export default Home;
