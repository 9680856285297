import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Header = () => {
  const menu = ["service", "partnershps", "become an expert", "pricing"];
  const [menuOpen, setMenuOpen] = useState(false);

  const openMobileMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const gtePathName = pathname.split("/");

  return (
    <>
      <div className="bg-[#FFB900]">
        {/* <div className="header"> */}
        <div className="containar">
          <div className="flex flex-row items-center justify-between h-22">
            <div className="flex items-center">
              <div className="w-30" onClick={() => window.location.assign("https://www.zonar.co/")}>
                <img
                  className="object-contain cursor-pointer"
                  src={require("../assets/images/logo.png")}
                  alt="logo"
                />
              </div>
              {/* menu  */}
              <ul className="items-center pl-9 mt-2 lg:flex hidden">
                <li className={`text-white ml-0`}>
                  <a
                    href="https://www.zonar.co/privacy-policy/"
                    className="capitalize font-bold text-base"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li className={`text-white ml-6`}>
                  <a href="https://www.zonar.co/terms-and-conditions/" className="capitalize font-bold text-base">
                    T&C
                  </a>
                </li>
                <li className={`text-white ml-6 `}>
                  <a href="https://www.zonar.co/faq/" className="capitalize font-bold text-base">
                    FAQ
                  </a>
                </li>
                <li className={`text-white ml-6`}>
                  <Link to="/" className="capitalize font-bold text-base">
                    Preview
                  </Link>
                </li>
              </ul>
            </div>
            <div className="lg:block lg:space-x-0 flex space-x-6 items-center">
              {/* menu bar icon  */}
              <div
                className="lg:hidden block cursor-pointer"
                onClick={openMobileMenu}
              >
                <svg
                  className="fill-white"
                  width={25}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M0 96C0 78.33 14.33 64 32 64H416C433.7 64 448 78.33 448 96C448 113.7 433.7 128 416 128H32C14.33 128 0 113.7 0 96zM0 256C0 238.3 14.33 224 32 224H416C433.7 224 448 238.3 448 256C448 273.7 433.7 288 416 288H32C14.33 288 0 273.7 0 256zM416 448H32C14.33 448 0 433.7 0 416C0 398.3 14.33 384 32 384H416C433.7 384 448 398.3 448 416C448 433.7 433.7 448 416 448z" />
                </svg>
              </div>
              <button
                type="button"
                onClick={() =>
                  window.location.assign("https://www.zonar.co/contact-us/")
                }
                className="sm:block lg:mt-1 mt-0 hidden border border-[#fff] text-white px-4 py-2 rounded-md font-semibold"
              >
                Contact Us
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Mobile Menu  */}
      <div
        className={`lg:hidden block w-full transition-all duration-500 absolute left-0 right-0 bg-[#FFB900] ${
          menuOpen
            ? "opacity-100 visible sm:h-48 shadow-md h-72"
            : "opacity-0 invisible h-0"
        }`}
        style={{ top: "98px", zIndex: 99999 }}
      >
        <div className="containar py-5">
          <ul>
            {menu.map((item, ind) => {
              return (
                <li
                  className="text-white leading-9 first:font-semibold text-base"
                  key={ind}
                >
                  {/* <a href={`#${item}`}>{item}</a> */}
                  <a href={"#"} className="capitalize text-lg">
                    {item}
                  </a>
                </li>
              );
            })}
          </ul>
          <button
            type="button"
            className="sm:hidden block border mt-6 border-white text-white px-5 py-3 rounded-md font-semibold"
          >
            Contact Us
          </button>
        </div>
      </div>
    </>
  );
};

export default Header;
