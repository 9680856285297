import React, { useState } from "react";
import handy from "../assets/images/service-icon1.png";
import builder from "../assets/images/builder.png";
import painter from "../assets/images/painter.png";
import lock from "../assets/images/lock.png";
import plumber from "../assets/images/plumber.png";
import elctswitch from "../assets/images/switch.png";
import repair from "../assets/images/repair.png";
import { useNavigate } from "react-router-dom";
import MapModal from "./MapModal";

const Services = () => {
  const [searchInput, setSearchInput] = useState("");

  const handymen = [
    {
      title: "Builder",
      icon: builder,
    },
    {
      title: "Painter",
      icon: painter,
    },
    {
      title: "Locksmith",
      icon: lock,
    },
    {
      title: "Plumber",
      icon: plumber,
    },
    {
      title: "Electrician",
      icon: elctswitch,
    },
    {
      title: "Repair & odd jobs",
      icon: repair,
    },
  ];

  const services = [
    {
      title: "Creative",
      icon: require("../assets/images/creative.png"),
    },
    {
      title: "Media",
      icon: require("../assets/images/media.png"),
    },
    {
      title: "Legal",
      icon: require("../assets/images/legal.png"),
    },
    {
      title: "Automotive",
      icon: require("../assets/images/car.png"),
    },
    {
      title: "Tech",
      icon: require("../assets/images/tv.png"),
    },
    {
      title: "Mobile repair",
      icon: require("../assets/images/mobile.png"),
    },
    {
      title: "Events",
      icon: require("../assets/images/event.png"),
    },
    {
      title: "Finance",
      icon: require("../assets/images/money.png"),
    },
  ];

  const healtAndService = [
    {
      title: "Beauty & Specialist",
      icon: require("../assets/images/beauty-special.png"),
    },
    {
      title: "Personal Trainer",
      icon: require("../assets/images/trainer.png"),
    },
    {
      title: "Physio &  Chiro",
      icon: require("../assets/images/psyco.png"),
    },
    {
      title: "Hair Stylist",
      icon: require("../assets/images/barber.png"),
    },
    {
      title: "Tattoo Artist",
      icon: require("../assets/images/tatto.png"),
    },
    {
      title: "Instructor",
      icon: require("../assets/images/instructor.png"),
    },
    {
      title: "Masseuse",
      icon: require("../assets/images/massuse.png"),
    },
    {
      title: "Nutrionist",
      icon: require("../assets/images/nutr.png"),
    },
  ];

  const educationService = [
    {
      title: "Arts",
      icon: require("../assets/images/arts.png"),
    },
    {
      title: "Music",
      icon: require("../assets/images/music.png"),
    },
    {
      title: "Driving Instructor",
      icon: require("../assets/images/driver.png"),
    },
    {
      title: "Religious Education",
      icon: require("../assets/images/religious.png"),
    },
    {
      title: "Humanities",
      icon: require("../assets/images/humanities.png"),
    },
    {
      title: "Science",
      icon: require("../assets/images/science.png"),
    },
    {
      title: "Language",
      icon: require("../assets/images/language.png"),
    },
    {
      title: "Maths",
      icon: require("../assets/images/math.png"),
    },
  ];

  const HomeService = [
    {
      title: "Aircon",
      icon: require("../assets/images/aircon.png"),
    },
    {
      title: "Pest Control",
      icon: require("../assets/images/press.png"),
    },
    {
      title: "Real Estate",
      icon: require("../assets/images/real-estate.png"),
    },
    {
      title: "Cleaner",
      icon: require("../assets/images/cleaner.png"),
    },
    {
      title: "Security",
      icon: require("../assets/images/security.png"),
    },
    {
      title: "Cooking",
      icon: require("../assets/images/cooking.png"),
    },
    {
      title: "Gardener",
      icon: require("../assets/images/guardian.png"),
    },
    {
      title: "Furniture Repair",
      icon: require("../assets/images/furtinure.png"),
    },
    {
      title: "Mover",
      icon: require("../assets/images/mover.png"),
    },
  ];

  const navigate = useNavigate();

  return (
    <div className="mt-16 containar">
      {/* service top  */}
      <div className="flex md:flex-row flex-col md:justify-between md:items-center">
        <h1 className="font-bold md:text-5xl text-3xl md:mb-0 mb-7">
          All Services
        </h1>

        <MapModal searchInput={setSearchInput} valueSearch={searchInput} />
      </div>

      {/* all services  */}

      <div className="flex flex-row flex-wrap -mx-4 mt-12">
        {/* Handymen */}

        <div className="md:basis-1/2 md:w-1/2 w-full p-4">
          <div className="z-bg-yellow flex justify-between items-center rounded-md p-4">
            <h3 className="text-white font-semibold text-xl">Handymen</h3>
            <img
              src={handy}
              className="w-12 h-16 object-contain"
              alt="service-icon"
            />
          </div>

          <div className="flex flex-row flex-wrap mt-8 -mx-4">
            {handymen.map((item, ind) => {
              return (
                <div className="basis-1/4 w-1/4 p-4" key={ind}>
                  <div
                    onClick={() =>
                      navigate(
                        `/handymen/${item.title
                          .toLowerCase()
                          .replace(/ /g, "-")
                          .replace(/[^\w-]+/g, "")}`,
                        { state: { name: item } }
                      )
                    }
                  >
                    <div className="w-12 h-12 relative mx-auto">
                      <div className="flex justify-center items-center h-full cursor-pointer">
                        <div className="absolute bg-[#FFB900] opacity-10 rounded-3xl top-0 left-0 bottom-0 right-0 w-full h-full"></div>
                        <img
                          src={item.icon}
                          alt="icon"
                          className="object-contain w-9 h-9"
                        />
                      </div>
                    </div>
                    <p className="text-xxs text-[#191B27] mt-2 text-center w-2/3 ml-auto mr-auto cursor-pointer">
                      {item.title}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* home service section  */}
        <div className="md:basis-1/2 md:w-1/2 w-full p-4">
          <div className="z-bg-purple flex justify-between items-center rounded-md p-4">
            <h3 className="text-white font-semibold text-xl">Home</h3>
            <img
              src={require("../assets/images/home.png")}
              className="w-16 h-16 object-contain"
              alt="service-icon"
            />
          </div>

          <div className="flex flex-row flex-wrap mt-8 -mx-4">
            {HomeService.map((item, ind) => {
              return (
                <div className="basis-1/4 w-1/4 p-4" key={ind}>
                  <div
                    className="cursor-pointer"
                    onClick={() =>
                      navigate(
                        `/home/${
                          item.title === "Cooking"
                            ? "cook"
                            : item.title
                                .toLowerCase()
                                .replace(/ /g, "-")
                                .replace(/[^\w-]+/g, "")
                        }`,
                        { state: { name: item } }
                      )
                    }
                  >
                    <div className="w-12 h-12 relative mx-auto">
                      <div className="flex justify-center items-center h-full">
                        <div className="absolute bg-[#7B7BEF] opacity-10 rounded-3xl top-0 left-0 bottom-0 right-0 w-full h-full"></div>
                        <img
                          src={item.icon}
                          alt="icon"
                          className="object-contain w-9 h-9"
                        />
                      </div>
                    </div>
                    <p className="text-xxs text-[#191B27] mt-2 text-center w-2/3 ml-auto mr-auto">
                      {item.title}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* Health & Beauty  */}
        <div className="md:basis-1/2 md:w-1/2 w-full p-4">
          <div className="z-bg-pink flex justify-between items-center rounded-md p-4">
            <h3 className="text-white font-semibold text-xl">
              Health & Beauty
            </h3>
            <img
              src={require("../assets/images/hearth.png")}
              className="w-12 h-16 object-contain"
              alt="service-icon"
            />
          </div>

          <div className="flex flex-row flex-wrap mt-8 -mx-4">
            {healtAndService.map((item, ind) => {
              return (
                <div className="basis-1/4 w-1/4 p-4" key={ind}>
                  <div
                    onClick={() =>
                      navigate(
                        `/health-and-beauty/${
                          item.title.toLowerCase() === "beauty & specialist"
                            ? "beauty-specialist"
                            : item.title
                                .toLowerCase()
                                .replace(/ /g, "-")
                                .replace(/[^\w-]+/g, "")
                        }`,
                        { state: { name: item } }
                      )
                    }
                  >
                    <div className="w-12 h-12 relative mx-auto">
                      <div className="flex justify-center items-center h-full cursor-pointer">
                        <div className="absolute bg-[#FF4370] opacity-10 rounded-3xl top-0 left-0 bottom-0 right-0 w-full h-full"></div>
                        <img
                          src={item.icon}
                          alt="icon"
                          className="object-contain w-9 h-9"
                        />
                      </div>
                    </div>
                    <p className="text-xxs text-[#191B27] mt-2 text-center w-2/3 ml-auto mr-auto cursor-pointer">
                      {item.title}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* Education  */}
        <div className="md:basis-1/2 md:w-1/2 w-full p-4">
          <div className="z-bg-dark-blue flex justify-between items-center rounded-md p-4">
            <h3 className="text-white font-semibold text-xl">Education</h3>
            <img
              src={require("../assets/images/education.png")}
              className="w-16 h-16 object-contain"
              alt="service-icon"
            />
          </div>

          <div className="flex flex-row flex-wrap mt-8 -mx-4">
            {educationService.map((item, ind) => {
              return (
                <div className="basis-1/4 w-1/4 p-4" key={ind}>
                  <div
                    className="cursor-pointer"
                    onClick={() =>
                      navigate(
                        `/education/${
                          item.title.toLowerCase() === "arts"
                            ? "art-teacher"
                            : item.title.toLowerCase() === "driving instructor"
                            ? "driving-teacher"
                            : item.title.toLowerCase() === "science"
                            ? "science-teacher"
                            : item.title.toLowerCase() === "language"
                            ? "language-teacher"
                            : item.title.toLowerCase() === "maths"
                            ? "math-teacher"
                            : item.title
                                .toLowerCase()
                                .replace(/ /g, "-")
                                .replace(/[^\w-]+/g, "")
                        }`,
                        { state: { name: item } }
                      )
                    }
                  >
                    <div className="w-12 h-12 relative mx-auto">
                      <div className="flex justify-center items-center h-full">
                        <div className="absolute bg-[#21CAFF] opacity-10 rounded-3xl top-0 left-0 bottom-0 right-0 w-full h-full"></div>
                        <img
                          src={item.icon}
                          alt="icon"
                          className="object-contain w-9 h-9"
                        />
                      </div>
                    </div>
                    <p className="text-xxs text-[#191B27] mt-2 text-center w-2/3 ml-auto mr-auto">
                      {item.title}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* services  */}
        <div className="md:basis-1/2 md:w-1/2 w-full p-4">
          <div className="z-bg-blue flex justify-between items-center rounded-md p-4">
            <h3 className="text-white font-semibold text-xl">Services</h3>
            <img
              src={require("../assets/images/service-icon2.png")}
              className="w-12 h-16 object-contain"
              alt="service-icon"
            />
          </div>

          <div className="flex flex-row flex-wrap mt-8 -mx-4">
            {services.map((item, ind) => {
              return (
                <div className="basis-1/4 w-1/4 p-4" key={ind}>
                  <div
                    onClick={() =>
                      navigate(
                        `/services/${
                          item.title.toLowerCase() === "mobile repair"
                            ? "electronic-repair"
                            : item.title.toLowerCase() === "finance"
                            ? "Financial"
                            : item.title
                                .toLowerCase()
                                .replace(/ /g, "-")
                                .replace(/[^\w-]+/g, "")
                        }`,
                        { state: { name: item } }
                      )
                    }
                  >
                    <div className="w-12 h-12 relative mx-auto">
                      <div className="flex justify-center items-center h-full cursor-pointer">
                        <div className="absolute bg-[#00DBD2] opacity-10 rounded-3xl top-0 left-0 bottom-0 right-0 w-full h-full"></div>
                        <img
                          src={item.icon}
                          alt="icon"
                          className="object-contain w-9 h-9"
                        />
                      </div>
                    </div>
                    <p className="text-xxs text-[#191B27] mt-2 text-center w-2/3 ml-auto mr-auto cursor-pointer">
                      {item.title}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* Other Services  */}
        <div className="md:basis-1/2 md:w-1/2 w-full p-4">
          <div className="z-bg-silver flex justify-between items-center rounded-md p-4">
            <h3 className="text-white font-semibold text-xl">Others</h3>
            <img
              src={require("../assets/images/others.png")}
              className="w-12 h-16 object-contain"
              alt="service-icon"
            />
          </div>

          <div className="flex flex-row flex-wrap mt-8 -mx-4">
            <div className="basis-1/4 w-1/4 p-4">
              <div
                onClick={() =>
                  navigate("/services/other-category", {
                    state: {
                      name: {
                        title: "Other Category",
                        icon: require("../assets/images/others.png"),
                      },
                    },
                  })
                }
              >
                <div className="w-12 h-12 relative mx-auto">
                  <div className="flex justify-center items-center h-full cursor-pointer">
                    <div className="absolute bg-black opacity-10 rounded-3xl top-0 left-0 bottom-0 right-0 w-full h-full"></div>
                    <img
                      src={require("../assets/images/others.png")}
                      alt="icon"
                      className="object-contain w-9 h-9"
                    />
                  </div>
                </div>
                <p className="text-xxs text-[#191B27] mt-2 text-center w-2/3 ml-auto mr-auto cursor-pointer">
                  Other Category
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
