import React from "react";

const NotFound = () => {
  return (
    <div className="bg-gray-100 h-screen w-full">
      <div className="flex items-center justify-center h-full">
        <h2 className="text-5xl text-black font-light">No Page Found</h2>
      </div>
    </div>
  );
};

export default NotFound;
