import React from "react";

const Footer = () => {
  return (
    <footer id="main-footer">
      <div class="containar">
        <div id="footer-widgets" class="clearfix">
          <div class="footer-widget">
            <div id="block-31" class="fwidget et_pb_widget widget_block">
              <div class="wp-container-3 wp-block-group">
                <div class="wp-container-2 wp-block-columns">
                  <div class="wp-container-1 wp-block-column"></div>
                </div>
              </div>
            </div>
            <div id="block-34" class="fwidget et_pb_widget widget_block">
              <ul
                style={{
                  listStyleType: "none",
                  fontFamily: "Urbanist",
                  float: "left",
                }}
              >
                <li>
                  <p
                    class="has-luminous-vivid-amber-color has-text-color"
                    style={{
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "16.8px",
                      letterSpacing: "-0.3px",
                      margin: "16px 0",
                    }}
                  >
                    Zonar
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      fontStyle: "normal",
                      fontSize: "14.2px",
                      margin: "18px 0px",
                    }}
                  >
                    About
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      fontStyle: "normal",
                      fontSize: "14.2px",
                      margin: "18px 0px",
                    }}
                  >
                    Contact
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      fontStyle: "normal",
                      fontSize: "14.2px",
                      margin: "18px 0px",
                    }}
                  >
                    Our Team
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      fontStyle: "normal",
                      fontSize: "14.2px",
                      margin: "18px 0px",
                    }}
                  >
                    Careers
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      fontStyle: "normal",
                      fontSize: "14.2px",
                      margin: "18px 0px",
                    }}
                  >
                    Pricing
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      fontStyle: "normal",
                      fontSize: "14.2px",
                      margin: "18px 0px",
                    }}
                  >
                    Press
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      fontStyle: "normal",
                      fontSize: "14.2px",
                      margin: "18px 0px",
                    }}
                  >
                    Blog
                  </p>
                </li>
              </ul>
              <ul
                style={{
                  listStyleType: "none",
                  fontFamily: "Urbanist",
                  float: "left",
                  marginLeft: "60px",
                }}
              >
                <li>
                  <p
                    class="has-luminous-vivid-amber-color has-text-color"
                    style={{
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "16.8px",
                      letterSpacing: "-0.3px",
                      margin: "16px 0",
                    }}
                  >
                    Support
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      fontStyle: "normal",
                      fontSize: "14.2px",
                      margin: "18px 0px",
                    }}
                  >
                    Help
                  </p>
                </li>
                <li>
                  <a href="https://www.zonar.co/faq/">
                    <p
                      style={{
                        fontStyle: "normal",
                        fontSize: "14.2px",
                        margin: "18px 0px",
                      }}
                    >
                      FAQ
                    </p>
                  </a>
                </li>
                <li>
                  <p
                    style={{
                      fontStyle: "normal",
                      fontSize: "14.2px",
                      margin: "18px 0px",
                    }}
                  >
                    Accessibility
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      fontStyle: "normal",
                      fontSize: "14.2px",
                      margin: "18px 0px",
                    }}
                  >
                    Security
                  </p>
                </li>
              </ul>
              <ul
                style={{
                  listStyleType: "none",
                  fontFamily: "Urbanist",
                  float: "left",
                  marginLeft: "50px",
                }}
              >
                <li>
                  <p
                    class="has-luminous-vivid-amber-color has-text-color"
                    style={{
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "16.8px",
                      letterSpacing: "-0.3px",
                      margin: "16px 0",
                    }}
                  >
                    Expert
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      fontStyle: "normal",
                      fontSize: "14.2px",
                      margin: "18px 0px",
                    }}
                  >
                    Become an Expert
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      fontStyle: "normal",
                      fontSize: "14.2px",
                      margin: "18px 0px",
                    }}
                  >
                    How It Works
                  </p>
                </li>
              </ul>
              <ul
                style={{
                  listStyleType: "none",
                  fontFamily: "Urbanist",
                  float: "left",
                  marginLeft: "50px",
                }}
              >
                <li>
                  <p
                    class="has-luminous-vivid-amber-color has-text-color"
                    style={{
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "16.8px",
                      letterSpacing: "-0.3px",
                      margin: "16px 0",
                    }}
                  >
                    Partnerships
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      fontStyle: "normal",
                      fontSize: "14.2px",
                      margin: "18px 0px",
                    }}
                  >
                    Corporate Partnerships
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      fontStyle: "normal",
                      fontSize: "14.2px",
                      margin: "18px 0px",
                    }}
                  >
                    Corporate Events
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div class="footer-widget">
            <div id="block-42" class="fwidget et_pb_widget widget_block">
              <ul style={{ listStyleType: "none", fontFamily: "Urbanist" }}>
                <li>
                  <p
                    class="has-luminous-vivid-amber-color has-text-color"
                    style={{
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "17px",
                      letterSpacing: "-0.3px",
                      margin: "16px 0",
                    }}
                  >
                    Download Our App
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      fontStyle: "normal",
                      fontSize: "14.2px",
                      margin: "18px 0px",
                      lineHeight: "24px",
                    }}
                  >
                    The{" "}
                    <span class="has-luminous-vivid-amber-color has-text-color">
                      Zonar
                    </span>{" "}
                    mobile app is the most convenient way to enjoy all Rinse
                    services.
                  </p>
                </li>
              </ul>
            </div>
            <div id="block-43" class="fwidget et_pb_widget widget_block">
              <div class="wp-container-6 wp-block-columns">
                <div class="wp-container-4 wp-block-column">
                  <figure class="wp-block-image size-full is-resized">
                    <a href="https://play.google.com/store/apps/details?id=co.zonar.prod">
                      <img
                        loading="lazy"
                        src={
                          "https://www.zonar.co/wp-content/uploads/2022/05/Mobile-app-store-badge-1.svg" ||
                          require("../assets/images/google-button.png")
                        }
                        alt=""
                        class="wp-image-777"
                        width="200"
                        height="60"
                      />
                    </a>
                  </figure>
                </div>

                <div class="wp-container-5 wp-block-column">
                  <figure class="wp-block-image size-full is-resized">
                    <a href="https://apps.apple.com/my/app/zonar/id1509428696">
                      <img
                        loading="lazy"
                        src={
                          "https://www.zonar.co/wp-content/uploads/2022/05/Mobile-app-store-badge.svg" ||
                          require("../assets/images/google-button.png")
                        }
                        alt=""
                        class="wp-image-776"
                        width="160"
                        height="30"
                      />
                    </a>
                  </figure>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>

      <div id="footer-bottom">
        <div class="containar clearfix">
          <ul class="et-social-icons">
            <li class="et-social-icon et-social-facebook">
              <a href="http://fb.zonar.co" class="icon">
                <svg
                  fill="#666"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                </svg>
              </a>
            </li>
            <li class="et-social-icon et-social-twitter">
              <a href="http://twitter.zonar.co" class="icon">
                <svg
                  fill="#666"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
                </svg>
              </a>
            </li>
            <li class="et-social-icon et-social-instagram">
              <a href="http://ig.zonar.co">
                <svg
                  fill="#666"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  style={{ width: "27px", height: "27px" }}
                >
                  <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                </svg>
              </a>
            </li>
          </ul>{" "}
        </div>
      </div>
    </footer>

    // <div className="mt-16 bg-[#2B2E43] pb-4">
    //   <div className="containar pt-12 pb-4">
    //     <div className="flex flex-row flex-wrap -mx-4">
    //       <div className="p-4 md:basis-3/5 md:w-3/5">
    //         <div className="flex flex-wrap -mx-4">
    //           <div className="p-4 sm:basis-1/5 basis-2/4 sm:w-1/5 w-2/4">
    //             <h4
    //               style={{ fontSize: "17px" }}
    //               className="text-[#FCB900] text-base font-bold mb-3"
    //             >
    //               Zonar
    //             </h4>
    //             <ul>
    //               <li className="leading-10">
    //                 <a href="#" className="text-white font-normal text-sm">
    //                   About
    //                 </a>
    //               </li>
    //               <li className="leading-10">
    //                 <a
    //                   href="https://www.zonar.co/contact-us/"
    //                   className="text-white font-normal text-sm"
    //                 >
    //                   Contact
    //                 </a>
    //               </li>
    //               <li className="leading-10">
    //                 <a href="#" className="text-white font-normal text-sm">
    //                   Our Team
    //                 </a>
    //               </li>
    //               <li className="leading-10">
    //                 <a href="#" className="text-white font-normal text-sm">
    //                   Careers
    //                 </a>
    //               </li>
    //               <li className="leading-10">
    //                 <a href="#" className="text-white font-normal text-sm">
    //                   Pricing
    //                 </a>
    //               </li>
    //               <li className="leading-10">
    //                 <a href="#" className="text-white font-normal text-sm">
    //                   Press
    //                 </a>
    //               </li>
    //               <li className="leading-10">
    //                 <a href="#" className="text-white font-normal text-sm">
    //                   Blog
    //                 </a>
    //               </li>
    //             </ul>
    //           </div>
    //           <div className="p-4 sm:basis-1/5 basis-2/4 sm:w-1/5 w-2/4">
    //             <h4
    //               style={{ fontSize: "17px" }}
    //               className="text-[#FCB900] font-bold mb-3"
    //             >
    //               Support
    //             </h4>
    //             <ul>
    //               <li className="leading-10">
    //                 <a href="#" className="text-white font-normal text-sm">
    //                   Help
    //                 </a>
    //               </li>
    //               <li className="leading-10">
    //                 <a
    //                   href="https://www.zonar.co/faq/"
    //                   className="text-white font-normal text-sm"
    //                 >
    //                   FAQ
    //                 </a>
    //               </li>
    //               <li className="leading-10">
    //                 <a href="#" className="text-white font-normal text-sm">
    //                   Accessibility
    //                 </a>
    //               </li>
    //               <li className="leading-10">
    //                 <a href="#" className="text-white font-normal text-sm">
    //                   Security
    //                 </a>
    //               </li>
    //             </ul>
    //           </div>
    //           <div className="p-4 sm:basis-1/5 basis-2/4 sm:w-1/5 w-2/4">
    //             <h4
    //               style={{ fontSize: "17px" }}
    //               className="text-[#FCB900] font-bold mb-3"
    //             >
    //               Expert
    //             </h4>
    //             <ul>
    //               <li className="leading-10">
    //                 <a href="#" className="text-white font-normal text-sm">
    //                   Become an Expert
    //                 </a>
    //               </li>
    //               <li className="leading-10">
    //                 <a href="#" className="text-white font-normal text-sm">
    //                   How It Works
    //                 </a>
    //               </li>
    //             </ul>
    //           </div>
    //           <div className="p-4 sm:basis-2/5 basis-2/4 sm:w-2/5 w-2/4">
    //             <h4
    //               style={{ fontSize: "17px" }}
    //               className="text-[#FCB900] font-bold mb-3"
    //             >
    //               Partnerships
    //             </h4>
    //             <ul>
    //               <li className="leading-10">
    //                 <a href="#" className="text-white font-normal text-sm">
    //                   Corporate Partnerships
    //                 </a>
    //               </li>
    //               <li className="leading-10">
    //                 <a href="#" className="text-white font-normal text-sm">
    //                   Corporate Events
    //                 </a>
    //               </li>
    //             </ul>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="p-4 md:basis-2/5 md:w-2/5 w-full xl:-ml-9 ml-0">
    //         <h4
    //           style={{ fontSize: "17px" }}
    //           className="text-[#FCB900] font-bold mb-3 pb-2 pt-4"
    //         >
    //           Download Our App
    //         </h4>
    //         <p className="text-white" style={{ fontSize: "15px" }}>
    //           The <span className="text-[#FFB900]">Zonar</span> mobile app is
    //           the most convenient way to enjoy all Rinse services.
    //         </p>
    //         <div className="flex" style={{marginTop: "70px"}}>
    //           <div>
    //             <a href="https://play.google.com/store/apps/details?id=co.zonar.prod">
    //               <img
    //                 src={require("../assets/images/google-button.png")}
    //                 alt="google-button"
    //                 className="w-70 object-contain"
    //               />
    //             </a>
    //           </div>
    //           <div className="-ml-2">
    //             <a href="https://apps.apple.com/my/app/zonar/id1509428696">
    //               <img
    //                 src={require("../assets/images/apple-button.png")}
    //                 alt="apple-button"
    //                 className="w-70 object-contain"
    //               />
    //             </a>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   {/* line  */}
    //   <hr className="border border-[#585E8A]" />
    //   {/* foooter bottom */}
    //   <div className="flex flex-wrap justify-between containar pt-8">
    //     <div className="pb-4">
    //       <p className="text-white text-sm">
    //         Copyright © 2022 Zonar. All rights reserved.
    //       </p>
    //     </div>
    //     <ul className="flex pb-4">
    //       <li>
    //         <a
    //           href="https://www.zonar.co/privacy-policy/"
    //           className="text-white text-sm"
    //         >
    //           Privacy Policy
    //         </a>
    //       </li>
    //       <li className="pl-8">
    //         <a
    //           href="https://www.zonar.co/terms-and-conditions/"
    //           className="text-white text-sm"
    //         >
    //           Terms & Conditions
    //         </a>
    //       </li>
    //     </ul>
    //     {/* icons  */}
    //     <ul className="flex pb-4">
    //       <li>
    //         {/* fb icon  */}
    //         <a href="https://www.facebook.com/ZonarFB/">
    //           <svg
    //             width={25}
    //             className="fill-white"
    //             xmlns="http://www.w3.org/2000/svg"
    //             viewBox="0 0 512 512"
    //           >
    //             <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" />
    //           </svg>
    //         </a>
    //       </li>
    //       <li className="pl-8">
    //         {/* twiter */}
    //         <a href="https://twitter.com/zonarapp">
    //           <svg
    //             width={25}
    //             className="fill-white"
    //             xmlns="http://www.w3.org/2000/svg"
    //             viewBox="0 0 512 512"
    //           >
    //             <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
    //           </svg>
    //         </a>
    //       </li>
    //       <li className="pl-8">
    //         {/* instagram */}
    //         <a href="https://www.instagram.com/zonar/">
    //           <svg
    //             width={25}
    //             className="fill-white"
    //             xmlns="http://www.w3.org/2000/svg"
    //             viewBox="0 0 448 512"
    //           >
    //             <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
    //           </svg>
    //         </a>
    //       </li>
    //     </ul>
    //   </div>
    // </div>
  );
};

export default Footer;
