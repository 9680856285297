import React, { useEffect, useState } from "react";
import { db } from "../config/firebase";
import { useLocation, useNavigate } from "react-router-dom";
import Rating from "react-rating";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";
import allListing from "../utils/json/allListing";
import getDistance from "../utils/helpers/distance";
import Footer from "../components/Footer";
import Header from "../components/Header";
import camelize from "../utils/helpers/camelize";
import LoaderSpinner from "../components/LoaderSpinner";
import MapModal from "../components/MapModal";
import AverageRating from "../utils/helpers/AverageRating";
import printFlags from "../utils/helpers/printFlags";
import DownloadPopup from "../components/DownloadPopup";
import noIcon from "../assets/images/no-icon.png";

const SingleCategory = () => {
  const [listingItems, setListingItems] = useState([]);
  const [position, setPosition] = useState(null);
  const [lastVisible, setLastVisible] = useState(null);
  const [noMoreFecth, setNoMoreFecth] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [loader, setLoader] = useState(false);
  const [iconPreview, setIconPreview] = useState(null);
  const [showModal, setShowModal] = React.useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();

  const pathName = location.pathname;
  const serviceCatgTitle = pathName.split("/")[2]?.split("-").join(" ");

  const serviceTitle = pathName.split("/")[1]?.split("-").join(" ");

  useEffect(() => {
    if (state === null) {
      for (let i = 0; i < allListing.length; i++) {
        if (
          allListing[i].title.toLowerCase() === serviceCatgTitle.toLowerCase()
        ) {
          setIconPreview(allListing[i].icon);
          break;
        }
      }
    }
  }, []);

  const initialLocationAccess = () => {
    let fromCurrent = JSON.parse(localStorage.getItem("userLatLng"));
    if (fromCurrent == null) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "granted") {
          navigator.geolocation.getCurrentPosition((position) => {
            let userLatLng = {
              lat: position?.coords?.latitude,
              lng: position?.coords?.longitude,
            };
            setPosition(userLatLng);
          });
        } else if (result.state === "denied") {
          toast.warn("Please allow the location first.");
          setLoader(false);
          localStorage.clear();
        }
        result.addEventListener("change", () => {
          if (result.state === "granted") {
            window.location.reload();
          } else if (result.state === "denied") {
            setLoader(false);
            toast.warn("Please allow the location first.");
          }
        });
      });
    } else {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "denied") {
          localStorage.clear();
          setPosition(null);
          setLoader(false);
        } else {
          setPosition(fromCurrent);
        }
      });
    }
  };

  useEffect(() => {
    initialLocationAccess();
  }, [searchInput]);

  const getSubCategoryData = async (serviceCatgeroyTitle) => {
    let catTitle = camelize(serviceCatgeroyTitle);

    setLoader(true);
    let fetchByCatTitle = catTitle?.includes("-")
      ? catTitle?.split("-").join("")
      : catTitle;

    if (position != null) {
      try {
        let query = await db
          .collection("providers")
          .where("subCategory", "==", fetchByCatTitle)
          .orderBy("updatedTimestamp", "desc")
          .limit(6)
          .get();

        if (query.docs.length) {
          setLastVisible(query.docs[query.docs.length - 1]);
          let temp = [];

          for (let i = 0; i < query.docs.length; i++) {
            let reviewData = await db
              .collection("reviews")
              .where("listingId", "==", query.docs[i].data().docId)
              .get();

            temp.push({
              ...query.docs[i].data(),
              rating: reviewData?.docs?.map((i) => i.data()) || [],
              distance: getDistance(
                position.lat,
                position.lng,
                query.docs[i].data().latitude,
                query.docs[i].data().longitude
              ).toFixed(1),
            });
          }
          if (temp.length) {
            setLoader(false);
            setListingItems(temp);
          }
        } else {
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getSubCategoryData(serviceCatgTitle);
  }, [searchInput, position]);

  const getMoreData = async () => {
    try {
      const result = await db
        .collection("providers")
        .where("subCategory", "==", camelize(serviceCatgTitle))
        .orderBy("updatedTimestamp", "desc")
        .startAfter(lastVisible)
        .limit(9)
        .get();

      if (result.docs.length) {
        let temp = [...listingItems];
        for (let i = 0; i < result.docs.length; i++) {
          let reviewData = await db
            .collection("reviews")
            .where("listingId", "==", result.docs[i].data().docId)
            .get();

          temp.push({
            ...result.docs[i].data(),
            rating: reviewData?.docs?.map((i) => i.data()) || [],
            distance: getDistance(
              position.lat,
              position.lng,
              result.docs[i].data().latitude,
              result.docs[i].data().longitude
            ).toFixed(1),
          });
        }
        setLastVisible(result.docs[result.docs.length - 1]);
        setListingItems(temp);
      }

      if (result.docs.length == 0) {
        setNoMoreFecth(true);
      } else {
        setNoMoreFecth(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMoreData = () => {
    setTimeout(() => {
      getMoreData();
    }, 500);
  };

  const getPosition = (lat, long) => {
    let distance = getDistance(position.lat, position.lng, lat, long).toFixed(
      1
    );
    return distance;
  };

  return (
    <div>
      <Header />
      <div className="containar mt-16">
        {/* service top  */}
        <div className="flex md:flex-row flex-col md:justify-between md:items-end">
          <div>
            {/* back arrow */}
            <div
              className="inline-block transition-all duration-200 bg-[#ffb600] mb-4 cursor-pointer rounded-sm hover:opacity-80"
              onClick={() => navigate("/")}
            >
              <div className="flex items-center px-4 py-2">
                <svg
                  width={17}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  className="fill-white"
                >
                  <path d="M447.1 256C447.1 273.7 433.7 288 416 288H109.3l105.4 105.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448s-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L109.3 224H416C433.7 224 447.1 238.3 447.1 256z" />
                </svg>
                <p className="ml-3 text-white font-semibold text-lg">Back</p>
              </div>
            </div>
            <div className="flex items-center">
              {/* icon  */}
              <div className="w-12 h-12 relative md:mx-auto">
                <div className="flex justify-center items-center h-full">
                  <div
                    className={`absolute opacity-10 rounded-3xl top-0 left-0 bottom-0 right-0 w-full h-full ${
                      serviceTitle == "handymen"
                        ? "bg-[#FFB900]"
                        : serviceTitle == "home"
                        ? "bg-[#7B7BEF]"
                        : serviceTitle == "services"
                        ? "bg-[#00DBD2]"
                        : serviceTitle == "health and beauty"
                        ? "bg-[#FF4370]"
                        : serviceTitle == "education"
                        ? "bg-[#21CAFF]"
                        : "bg-[#000]"
                    }`}
                  ></div>
                  <img
                    src={state?.name?.icon || iconPreview || noIcon}
                    alt="icon"
                    className="object-contain w-9 h-9"
                  />
                </div>
              </div>
              <div className="ml-8">
                <h4
                  className="text-xl font-light capitalize cursor-pointer"
                  onClick={() => navigate("/")}
                >
                  {serviceTitle} /
                </h4>
                <h1 className="capitalize font-bold md:text-5xl text-3xl">
                  {serviceCatgTitle}
                </h1>
              </div>
            </div>
          </div>
          <MapModal searchInput={setSearchInput} valueSearch={searchInput} />
        </div>

        {/* print categories  */}
        {!loader && listingItems.length ? (
          <InfiniteScroll
            dataLength={listingItems?.length}
            next={fetchMoreData}
            hasMore={noMoreFecth ? false : true}
            loader={
              <>
                <LoaderSpinner />
              </>
            }
            style={{ overflow: "hidden" }}
          >
            <div className="flex flex-wrap -mx-4 mt-12 mb-11">
              {listingItems
                .sort(function (a, b) {
                  return a.distance - b.distance;
                })
                ?.map((item, ind) => {
                  return (
                    <div className="p-4 md:basis-1/3 md:w-1/3 w-full" key={ind}>
                      <div className="shadow-md rounded-lg">
                        <div
                          className="w-full h-48 pb-2 cursor-pointer"
                          onClick={() => setShowModal(true)}
                        >
                          <img
                            src={
                              item?.urls[0] ||
                              "https://www.incplan.net/wp-content/uploads/2014/03/default_image_01-1024x1024-570x321.png"
                            }
                            alt="category-image"
                            className="object-cover w-full h-full rounded-tr-md rounded-tl-md"
                          />
                        </div>
                        <div className="px-4">
                          <h3
                            onClick={() => setShowModal(true)}
                            className="text-[#191B27] font-semibold text-sm text-elipse cursor-pointer"
                          >
                            {item?.name}
                          </h3>
                          <h5
                            className="text-[#191B27] text-elipse"
                            style={{ fontSize: "10px" }}
                          >
                            {item?.tagline
                              ? item?.tagline
                              : "Tagline goes here"}
                          </h5>
                          {/* rating */}
                          <div className="flex items-center">
                            <Rating
                              readonly={true}
                              initialRating={AverageRating(item.rating)}
                              emptySymbol={
                                <svg
                                  style={{ width: "13.5px" }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 576 512"
                                  fill="#e7e7e7"
                                >
                                  <path d="M381.2 150.3L524.9 171.5C536.8 173.2 546.8 181.6 550.6 193.1C554.4 204.7 551.3 217.3 542.7 225.9L438.5 328.1L463.1 474.7C465.1 486.7 460.2 498.9 450.2 506C440.3 513.1 427.2 514 416.5 508.3L288.1 439.8L159.8 508.3C149 514 135.9 513.1 126 506C116.1 498.9 111.1 486.7 113.2 474.7L137.8 328.1L33.58 225.9C24.97 217.3 21.91 204.7 25.69 193.1C29.46 181.6 39.43 173.2 51.42 171.5L195 150.3L259.4 17.97C264.7 6.954 275.9-.0391 288.1-.0391C300.4-.0391 311.6 6.954 316.9 17.97L381.2 150.3z" />
                                </svg>
                              }
                              fullSymbol={
                                <img
                                  src={require("../assets/images/Star.png")}
                                  className="w-4-5"
                                />
                              }
                            />
                            <h5 className="text-xs pl-1.5 text-[#424667]">
                              {AverageRating(item?.rating)}{" "}
                              <span>({item?.rating?.length} Reviews)</span>
                            </h5>
                          </div>
                          <p
                            className="text-elipse text-[#424667]"
                            style={{ fontSize: "10px" }}
                          >
                            {item?.description ? item?.description : "N/A"}
                          </p>
                          <div className="flex justify-between pb-4 pt-1 items-center">
                            <ul className="flex">
                              {item?.spokenLanguage?.map((lang, ind) => {
                                return (
                                  <li className="first:pl-0 pl-1" key={ind}>
                                    <div>{printFlags(lang)}</div>
                                  </li>
                                );
                              })}
                            </ul>
                            <h5 className="text-xs font-semibold">
                              {getPosition(item?.latitude, item?.longitude)} Km
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </InfiniteScroll>
        ) : (
          <div className="py-24">
            {loader && <LoaderSpinner />}
            {!loader && !listingItems.length ? (
              <p className="text-center text-2xl font-semibold">
                No Data Found..
              </p>
            ) : (
              ""
            )}
          </div>
        )}
      </div>

      <DownloadPopup showModal={showModal} setShowModal={setShowModal} />
      <Footer />
    </div>
  );
};

export default SingleCategory;
