import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./Pages/Home";
import SingleCategory from "./Pages/SingleCategory";
import NotFound from "./components/NotFound";

function App() {
  return (
    <>
      <ToastContainer className="toast-position" />
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/:service/:name" element={<SingleCategory />} />
          <Route exact path="/search/:title" element={<SingleCategory />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
