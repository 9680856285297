import countries from "../json/countries.json";

const printFlags = (char) => {
  for (let i = 0; i < countries.length; i++) {
    if (countries[i].flag == char) {
      let name = countries[i].iso.toLowerCase();

      return (
        <img
          src={`https://hatscripts.github.io/circle-flags/flags/${name}.svg`}
          alt="flag"
          width="16"
        />
      );
    }
  }
};

export default printFlags;
