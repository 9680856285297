import Geocode from "react-geocode";

const currentAdress = (lat, long) => {
  Geocode.setApiKey("AIzaSyDyHw1ODJ-q3HKSneTCp6N66sKaLLjx-84");

  // Get address from latitude & longitude.
  Geocode.fromLatLng(lat, long).then(
    (response) => {
      let address = response.results[0].formatted_address;
      localStorage.setItem("address", JSON.stringify(address));
    },
    (error) => {
      console.error(error);
    }
  );
};

export default currentAdress;
