import React from "react";

const DownloadPopup = (props) => {
  return (
    <>
      <div
        style={{ transition: "0.5s all ease" }}
        className={
          props.showModal
            ? "flex justify-center items-center opacity-100 visible overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            : "opacity-0 invisible h-0 w-0"
        }
      >
        <div
          className={`relative w-auto my-6 mx-auto max-w-3xl ${
            !props.showModal && "hidden"
          }`}
        >
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-bold pr-5">Download Our App</h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => props.setShowModal(false)}
              >
                <span className="bg-transparent text-black opacity-80 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto">
              <div className="mt-4 flex">
                <div>
                  <a href="https://play.google.com/store/apps/details?id=co.zonar.prod">
                    <img
                      src={require("../assets/images/google-button.png")}
                      alt="google-button"
                      className="w-2/3 object-contain mx-auto"
                    />
                  </a>
                </div>
                <div className="sm:-ml-16 -ml-10">
                  <a href="https://apps.apple.com/my/app/zonar/id1509428696">
                    <img
                      src={require("../assets/images/apple-button.png")}
                      alt="apple-button"
                      className="w-2/3 object-contain mx-auto"
                    />
                  </a>
                </div>
              </div>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => props.setShowModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          props.showModal ? "opacity-40 fixed inset-0 z-40 bg-black" : "hidden"
        }
      ></div>
    </>
  );
};

export default DownloadPopup;
