const allListing = [
  {
    title: "Builder",
    icon: require("../../assets/images/builder.png"),
  },
  {
    title: "Painter",
    icon: require("../../assets/images/painter.png"),
  },
  {
    title: "Locksmith",
    icon: require("../../assets/images/lock.png"),
  },
  {
    title: "Plumber",
    icon: require("../../assets/images/plumber.png"),
  },
  {
    title: "Electrician",
    icon: require("../../assets/images/switch.png"),
  },
  {
    title: "repair  odd jobs",
    icon: require("../../assets/images/repair.png"),
  },
  {
    title: "Creative",
    icon: require("../../assets/images/creative.png"),
  },
  {
    title: "Media",
    icon: require("../../assets/images/media.png"),
  },
  {
    title: "Legal",
    icon: require("../../assets/images/legal.png"),
  },
  {
    title: "Automotive",
    icon: require("../../assets/images/car.png"),
  },
  {
    title: "Tech",
    icon: require("../../assets/images/tv.png"),
  },
  {
    title: "Electronic Repair",
    icon: require("../../assets/images/mobile.png"),
  },
  {
    title: "Events",
    icon: require("../../assets/images/event.png"),
  },
  {
    title: "Financial",
    icon: require("../../assets/images/money.png"),
  },
  {
    title: "Beauty Specialist",
    icon: require("../../assets/images/beauty-special.png"),
  },
  {
    title: "Personal Trainer",
    icon: require("../../assets/images/trainer.png"),
  },
  {
    title: "Physio Chiro",
    icon: require("../../assets/images/psyco.png"),
  },
  {
    title: "Hair Stylist",
    icon: require("../../assets/images/barber.png"),
  },
  {
    title: "Tattoo Artist",
    icon: require("../../assets/images/tatto.png"),
  },
  {
    title: "Instructor",
    icon: require("../../assets/images/instructor.png"),
  },
  {
    title: "Masseuse",
    icon: require("../../assets/images/massuse.png"),
  },
  {
    title: "Nutrionist",
    icon: require("../../assets/images/nutr.png"),
  },
  {
    title: "Art Teacher",
    icon: require("../../assets/images/arts.png"),
  },
  {
    title: "Music",
    icon: require("../../assets/images/music.png"),
  },
  {
    title: "Driving Teacher",
    icon: require("../../assets/images/driver.png"),
  },
  {
    title: "Religious Education",
    icon: require("../../assets/images/religious.png"),
  },
  {
    title: "Humanities",
    icon: require("../../assets/images/humanities.png"),
  },
  {
    title: "Science Teacher",
    icon: require("../../assets/images/science.png"),
  },
  {
    title: "Language Teacher",
    icon: require("../../assets/images/language.png"),
  },
  {
    title: "Math Teacher",
    icon: require("../../assets/images/math.png"),
  },
  {
    title: "Aircon",
    icon: require("../../assets/images/aircon.png"),
  },
  {
    title: "Pest Control",
    icon: require("../../assets/images/press.png"),
  },
  {
    title: "Real Estate",
    icon: require("../../assets/images/real-estate.png"),
  },
  {
    title: "Cleaner",
    icon: require("../../assets/images/cleaner.png"),
  },
  {
    title: "Security",
    icon: require("../../assets/images/security.png"),
  },
  {
    title: "Cook",
    icon: require("../../assets/images/cooking.png"),
  },
  {
    title: "Gardener",
    icon: require("../../assets/images/guardian.png"),
  },
  {
    title: "Furniture Repair",
    icon: require("../../assets/images/furtinure.png"),
  },
  {
    title: "Mover",
    icon: require("../../assets/images/mover.png"),
  },
  {
    title: "Other Category",
    icon: require("../../assets/images/others.png"),
  },
];

export default allListing;
