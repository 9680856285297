import React, { useState } from "react";
import { useEffect } from "react";
import Geocode from "react-geocode";
import { toast } from "react-toastify";
import currentAdress from "../utils/helpers/currentAdress";
import GoogleMap from "./GoogleMap";
Geocode.setApiKey("AIzaSyDyHw1ODJ-q3HKSneTCp6N66sKaLLjx-84");

function MapModal({ searchInput, valueSearch, location }) {
  const [showModal, setShowModal] = useState(false);
  const [latLang, setLatLang] = useState({});
  const [markerLatLang, setMarkerLatLang] = useState({});
  const [addressFromMap, setAddressFromMap] = useState(null);
  const [mapInputVal, setMapInputVal] = useState("");
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    let address = JSON.parse(localStorage.getItem("address"));

    if (address == null) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "granted") {
          setShowError(false);
          navigator.geolocation.getCurrentPosition((position) => {
            let userLatLng = {
              lat: position?.coords?.latitude,
              lng: position?.coords?.longitude,
            };
            setLatLang(userLatLng);
            if (userLatLng.lat && userLatLng.lng) {
              Geocode.fromLatLng(
                position?.coords?.latitude,
                position?.coords?.longitude
              ).then(
                (response) => {
                  let address = response.results[0].formatted_address;
                  setMapInputVal(address);
                },
                (error) => {
                  console.error(error);
                }
              );
            }
          });
        } else {
          setShowError(true);
        }
      });
    } else {
      setMapInputVal(address);
    }
  }, []);

  const saved = () => {
    setShowModal(false);
    searchInput(addressFromMap);
    if (addressFromMap) {
      localStorage.setItem("userLatLng", JSON.stringify(latLang));
      currentAdress(latLang?.lat, latLang?.lng);
    }
  };

  return (
    <>
      <div className="border border-[#C9CCDD] rounded-lg md:mt-0 w-search mt-5">
        <div className="flex flex-row justify-between items-center">
          <form className="w-11/12">
            <input
              type="text"
              onFocus={() => {
                if (!showError) {
                  setShowModal(true);
                }
                else{
                  toast.warn("Please allow the location first.");
                }
              }}
              defaultValue={valueSearch ? valueSearch : mapInputVal}
              placeholder="Search nearby location.."
              className="text-[#424667] text-ellipsis w-full caret-transparent font-light sm:text-lg text-base outline-none border-none pr-3"
            />
          </form>
          <img
            src={require("../assets/images/search-normal.png")}
            alt="search-icon"
            className="object-contain"
          />
        </div>
      </div>
      {showModal && !showError ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-modal my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="modal-inner-scroll border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    Search Nearby Location
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <div>
                    <GoogleMap
                      setLatLang={setLatLang}
                      setMarkerLatLang={setMarkerLatLang}
                      addressFromMap={setAddressFromMap}
                      valueSearch={valueSearch}
                      latLang={latLang}
                    />
                    {/* <GoogleMap2/> */}
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={saved}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

export default MapModal;
