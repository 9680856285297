import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Geocode from "react-geocode";

Geocode.setApiKey("AIzaSyDyHw1ODJ-q3HKSneTCp6N66sKaLLjx-84");
export class GoogleMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: this.props.valueSearch,
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      mapCenter: { lat: 24.828401, lng: 67.1060354 },
      zoom: 10,
      zoomClass: false,
    };
  }

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    
    this.setState({ address });
    this.props.addressFromMap(address);
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        let userLatLng = {
          lat: latLng?.lat,
          lng: latLng?.lng,
        };

        this.props.setLatLang(userLatLng);

        this.setState({ mapCenter: latLng });
        this.setState({ zoomClass: true });
      })
      .catch((error) => console.error("Error", error));
  };

  handleMarker(e, b) {
  
    let userLatLng = {
      lat: b?.position?.lat(),
      lng: b?.position?.lng(),
    };
    localStorage.setItem("userLatLng", JSON.stringify(userLatLng));
    Geocode.fromLatLng(b?.position?.lat(), b?.position?.lng()).then(
      (response) => {
        let address = response.results[0].formatted_address;
        localStorage.setItem("address", JSON.stringify(address));
        document.getElementById("autoInput").value = address;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  componentDidMount() {
    let fromCurrent = JSON.parse(localStorage.getItem("userLatLng"));
    this.setState({ mapCenter: fromCurrent });

    let address = JSON.parse(localStorage.getItem("address"));
    this.setState({ address: address });
  }

  render() {
    return (
      <div id="googleMaps">
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div className="w-full">
              <div className="relative">
                <input
                  type="search"
                  {...getInputProps({
                    placeholder: "Search Places ...",
                    id: "autoInput",
                    className:
                      "w-full outline-none rounded-lg py-2.5 pl-2 mb-4 pr-12 text-ellipsis border border-[#000]",
                  })}
                />

                <div
                  className={`absolute right-4 font-bold cursor-pointer ${
                    !this.state.address && "hidden"
                  }`}
                  onClick={() => this.setState({ address: "" })}
                  style={{ top: "1.1rem" }}
                >
                  <svg
                    className="fill-gray-500"
                    width={8}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
                  </svg>
                </div>
                <div
                  className={`absolute top-12 w-full ${
                    suggestions.length && "border border-black"
                  } `}
                  style={{ zIndex: 9999 }}
                >
                  {loading && <div className="bg-[#fff] p-2">Loading...</div>}
                  {suggestions?.map((suggestion, ind) => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";

                    const style = suggestion.active
                      ? { backgroundColor: "#ddd", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <div
                        key={ind}
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <p className="text-black font-semibold py-1 px-2">
                          {suggestion.description}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </PlacesAutocomplete>

        <div className="googleMaps-container mt-2">
          <Map
            google={this.props.google}
            center={{
              lat: this.state?.mapCenter?.lat || this.props.latLng.lat,
              lng: this.state?.mapCenter?.lng || this.props.latLng.lng,
            }}
            zoom={this.state.zoom}
          >
            <Marker
              title="Your Location"
              draggable={true}
              position={{
                lat: this.state?.mapCenter?.lat,
                lng: this.state?.mapCenter?.lng,
              }}
              onDragend={this.handleMarker}
            />
          </Map>
        </div>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDyHw1ODJ-q3HKSneTCp6N66sKaLLjx-84",
})(GoogleMap);
